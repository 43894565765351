// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getPaymentoucherDetail = createAsyncThunk('appUsers/getPaymentoucherDetail', async id => {
  console.log("id>>>", id)
  const response = await axios.get(`${process.env.REACT_APP_API_URL}admin/paymentVoucherDetail/${id}`)
  console.log("paymentVoucherDetail>>", response)
  return response.data.paymentVoucherDetail   
})

export const allCounts = createAsyncThunk('appUsers/allCounts', async params => {
  // console.log("params>>", params)
  const response = await axios.get(`${process.env.REACT_APP_API_URL}admin/allCounts`, { params })
  console.log("Mainresponse>>", response)
  return {
    params,
    allData:response.data.data
    // data: response.data.paymentVoucher,
    // totalPages: response.data.paymentVoucherCount
  }
})

export const appUsersSlice = createSlice({
  name: 'allCounts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // .addCase(getUserData.fulfilled, (state, action) => {
      //   state.allData = action.payload.allData
      // })
      .addCase(allCounts.fulfilled, (state, action) => {
        state.allData = action.payload.allData
      })
  }
})

export default appUsersSlice.reducer
