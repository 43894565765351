// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import bills from '@src/views/apps/bills/store'
import bilty from '@src/views/apps/bilties/store'
import requests from '@src/views/apps/requests/store'
import quotation from '@src/views/apps/quotation/store'
import paymentVoucher from '@src/views/apps/paymentVoucher/store'
import surveyList from '@src/views/apps/surveyList/store'
import vehicleCondition from '@src/views/apps/vehicleCondition/store'
import transpoters from '@src/views/apps/user/transpoterStore'
import drivers from '@src/views/apps/user/driverStore'
import trackDriver from '@src/views/apps/trackDriver/store'
import support from '@src/views/apps/support/store'
import dashboard from '@src/views/dashboard/ecommerce/store'
import notifications from '@src/views/apps/notification/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  bills,
  bilty,
  requests,
  quotation,
  paymentVoucher,
  surveyList,
  vehicleCondition,
  transpoters,
  drivers,
  trackDriver,
  support,
  dashboard,
  notifications
}

export default rootReducer
