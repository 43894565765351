// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getallDrivers = createAsyncThunk('appUsers/getallDrivers', async params => {
  // console.log("params>>", params)
  const response = await axios.get(`${process.env.REACT_APP_API_URL}admin/allDrivers`, { params })
  console.log("response>>", response)
  return {
    params,
    allData: response.data.alldrivers,
    data: response.data.drivers,
    totalPages: response.data.driversCount
  }
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  console.log("getstate>>>", getState())
  await axios.get(`${process.env.REACT_APP_API_URL}admin/removeDriver/${id}`)
  await dispatch(getallDrivers(getState().drivers.params))
  // await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'getallDrivers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getallDrivers.fulfilled, (state, action) => {
        state.allData = action.payload.allData
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appUsersSlice.reducer
