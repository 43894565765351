// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data

})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  console.log("params>>", params)
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const vehicleConditionDetail = createAsyncThunk('appUsers/vehicleConditionDetail', async id => {
  console.log("id>>>", id)
  const response = await axios.get(`${process.env.REACT_APP_API_URL}admin/vehicleConditionDetail/${id}`)
  console.log("surveyListDetail>>", response)
  return response.data.vehicleConditionDetail
})


export const getAllvehicleCondition = createAsyncThunk('appUsers/getAllvehicleCondition', async params => {
  // console.log("params>>", params)
  const response = await axios.get(`${process.env.REACT_APP_API_URL}admin/vehicleCondition`, { params })
  console.log("response>>", response)
  return {
    params,
    allData: response.data.allvehicleCondition,
    data: response.data.vehicleCondition,
    totalPages: response.data.vehicleConditionCount
  }
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  console.log("getState>>>", getState())
  await axios.get(`${process.env.REACT_APP_API_URL}admin/removeVehicleCondition/${id}`)
  await dispatch(getAllvehicleCondition(getState().vehicleCondition.params))
  // await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'getAllvehicleCondition',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(getAllvehicleCondition.fulfilled, (state, action) => {
        state.allData = action.payload.allData
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(vehicleConditionDetail.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
